body {
    background-color: #f5f5f5 !important;
  }
  
  .active {
    background-color: red !important;
    border-left: #3f48c0 7px solid;
    font-weight: 600;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.202759px;
    color: #3f48c0;
  }
  